/* # Setup devices width */
/* # Variables for mobile first approach and strict ranges */
* {
  padding: 0;
  margin: 0;
  font-family: 'Roboto', sans-serif;
}
.contacts__content {
  padding: 0 10px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contacts__content {
    padding: 0 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contacts__content {
    padding: 0 25px;
  }
}
@media only screen and (min-width: 1200px) {
  .contacts__content {
    width: 80vw;
    max-width: 1100px;
    margin: 0 auto;
  }
}
.contacts__title {
  line-height: 40px;
  margin-top: 20px;
  margin-bottom: 80px;
  text-align: center;
  font-weight: 500;
  font-size: 20px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contacts__title {
    font-size: 22px;
  }
}
@media only screen and (min-width: 992px) {
  .contacts__title {
    text-align: left;
    font-size: 24px;
  }
}
.contacts__information {
  width: 90%;
  margin: 0 auto;
  margin-bottom: 80px;
}
.contacts__information_map {
  width: 100%;
  margin-bottom: 50px;
  max-width: 500px;
  margin: 0 auto;
}
.contacts__information_map-i {
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .contacts__information_map {
    width: 45%;
    float: left;
  }
}
@media only screen and (min-width: 992px) {
  .contacts__information {
    width: 100%;
  }
}
.contacts__information_text {
  width: 100%;
  margin-bottom: 50px;
}
@media only screen and (min-width: 768px) {
  .contacts__information_text {
    width: 45%;
    float: right;
  }
}
.contacts__information_text-i {
  line-height: 30px;
  font-size: 15px;
  margin-bottom: 5px;
}
.contacts__information_text-address {
  font-weight: bold;
}
.contacts__information_phone {
  text-decoration: none;
  color: #000;
  font-weight: 700;
  font-size: 15px;
}
.contacts__information_mail {
  text-decoration: none;
  color: #3567a2;
  font-weight: 700;
  font-size: 15px;
}
.contacts__information_title {
  text-align: center;
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 10px;
}
.contacts__form {
  width: 100%;
  margin-bottom: 80px;
  float: left;
}
@media only screen and (min-width: 768px) {
  .contacts__form {
    width: 80%;
  }
}
.contacts__form_title {
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
  margin-top: 30px;
  font-size: 20px;
}
@media only screen and (min-width: 768px) {
  .contacts__form_title {
    text-align: left;
    font-weight: 500;
  }
}
@media only screen and (min-width: 992px) {
  .contacts__form_title {
    font-size: 22px;
  }
}
.contacts__form_input {
  width: 80%;
  max-width: 350px;
  display: block;
  margin: 0 auto;
  height: 30px;
  margin-bottom: 20px;
  border: 1px solid #a9a9a9;
  padding-left: 10px;
  font-size: 10px;
}
.contacts__form_input-fields {
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .contacts__form_input-fields {
    width: 50%;
    float: left;
  }
}
@media only screen and (min-width: 768px) {
  .contacts__form_input {
    width: 90%;
    max-width: none;
    float: left;
    padding-left: 18px;
    font-size: 13px;
  }
}
@media only screen and (min-width: 1200px) {
  .contacts__form_input {
    height: 40px;
  }
}
.contacts__form_text {
  width: 80%;
  max-width: 350px;
  margin: 0 auto;
  margin-bottom: 50px;
}
@media only screen and (min-width: 768px) {
  .contacts__form_text {
    width: 40%;
    max-width: none;
    float: right;
    margin-left: 0;
  }
}
.contacts__form_text-i {
  width: 100%;
  height: 150px;
  resize: none;
  font-size: 10px;
  border: 1px solid #a9a9a9;
}
@media only screen and (min-width: 768px) {
  .contacts__form_text-i {
    font-size: 13px;
    padding: 10px 10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contacts__form_text-i {
    height: 170px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contacts__form_text-i {
    height: 170px;
  }
}
@media only screen and (min-width: 1200px) {
  .contacts__form_text-i {
    height: 210px;
  }
}
@media only screen and (min-width: 768px) {
  .contacts__form_text {
    width: 45%;
    float: right;
  }
}
.contacts__form_submit {
  width: 100%;
}
.contacts__form_submit-i {
  width: 250px;
  max-width: 80%;
  height: 70px;
  display: block;
  margin: 0 auto;
  border: none;
  background-color: #006fc4;
  color: #fff;
  border: 1px solid #a9a9a9;
  transition: .5s;
}
.contacts__form_submit-i:hover {
  background-color: #fff;
  color: #000;
}
@media only screen and (min-width: 768px) {
  .contacts__form_submit-i {
    margin-left: 0;
  }
}
@media only screen and (min-width: 768px) {
  .contacts__form_submit {
    display: inline-block;
  }
}
